body {
  /* background-image: url('/assets/background.jpg'); */
  /* background: rgb(4,40,40) !important; */
  background: black !important;
  overflow-x: hidden;
  padding-bottom: 10rem;
}
.App {
  text-align: center;
  /* display: flex; */
}

.Container {
  text-align: center;
  z-index: 1 !important;
}

.Top {
  background-color: whitesmoke;
  opacity: 0.6;
  padding-left: 10vw;
  padding-right: 10vw;
  border-radius: 10px;
  color: black;
  display: inline-block;
  margin: 5vh;
  user-select: none;
}
.clearCanvas {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.clearCanvas:hover {
  opacity: 1;
  cursor: pointer;
}
.clearCanvas:before, .clearCanvas:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #DDD;
}
.clearCanvas:before {
  transform: rotate(45deg);
}
.clearCanvas:after {
  transform: rotate(-45deg);
}


@media (max-width: 450px) {
  #top {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    border-radius: 0;
  }
}

#root {
  padding-bottom: 0;
}

#foot {
  position: fixed;
  display: flex;
  width: 100vw;
  z-index: 1;
  bottom: 0;
  background: rgb(4,40,40, 0.8);
  padding: 20px;
  color:whitesmoke;
  justify-content: space-around;
}
#foot .i {
  /* background: rgb(74,5,0, 1); */
  border: 1px solid white;
  color: white;
  height: 2rem;
  width: 6rem;
  border-radius: 6px;
  justify-content: space-around;
  display:flex;
  align-items: center;
  color: whitesmoke;
  text-decoration: none;
  cursor: pointer;
  user-select: none !important;
}
#foot .i:hover {
  background: white;
  border: 1px solid black;
  color: black;
}

@media(max-width: 670px) {
  #foot {
    justify-content: left;
    overflow-x: scroll;
  }
  #foot .i {
    margin-left: 5vw;
  }
}