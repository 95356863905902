
  .Container .Card {
    background-color: rgb(5,43,36, .75);
    border-radius: 20px;
    color:whitesmoke;
    text-align: left;
    margin: 2%;
    width: 100%;
    /* min-width: 30rem; */
    max-width: 90%;
    display: inline-flex;
    user-select: none;
  }

  .Header, .Text {
    user-select: none;
  }
  


  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* margin: 0; */
  }
  
  .react-player {
    position: absolute;
    top: 12%;
    left: 12%;
  }
  
  #spotify {
    position: relative;
    width: 100%;
  }
  